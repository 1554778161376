import { gsap } from "gsap"
import { fresponsive } from "library/fullyResponsive"
import type { UniversalLinkProps } from "library/Loader/UniversalLink"
import UniversalLink from "library/Loader/UniversalLink"
import { useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

export default function Button({
  className,
  children,
  ...props
}: UniversalLinkProps) {
  const [inner, setInner] = useState<Element | null>(null)

  const handleEnter = () => {
    gsap.to(inner, {
      backgroundImage: colors.buttonHover,
      duration: 0.3,
    })
  }

  const handleLeave = () => {
    gsap.to(inner, {
      backgroundImage: colors.buttonPrimary,
      duration: 0.3,
    })
  }

  return (
    <Wrapper
      onMouseLeave={handleLeave}
      onMouseEnter={handleEnter}
      className={className}
      {...props}
    >
      <Inner ref={setInner}>{children}</Inner>
    </Wrapper>
  )
}

const Wrapper = styled(UniversalLink)``

const Inner = styled.div`
  border: 1px solid #0e66da;
  background: linear-gradient(
    #19a4f2 0%,
    #308aff 55.71%,
    #2c82f4 55.72%,
    #1e75e8 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.grayWhite};
  ${textStyles.bodyButton}

  ${fresponsive(css`
    padding: 14px 37px;
    height: 47px;
    border-radius: 160px;
    box-shadow:
      0 1px 4px 0 rgb(0 0 0 / 18%),
      0 2px 3px 2px rgb(255 255 255 / 13%) inset;
  `)}
`
