import Footer from "components/Footer"
import Header from "components/Header"
import Preloader from "components/Preloader"
import { fresponsive } from "library/fullyResponsive"
import { useBackButton } from "library/Loader/TransitionUtils"
import { useTrackPageReady } from "library/pageReady"
import Scroll from "library/Scroll"
import useCSSHeightVariables from "library/useCssHeightVariables"
import { createGlobalStyle, css, styled } from "styled-components"
import colors from "styles/colors"

import ContactForm from "./Form/Form"
import Modal from "./Modal"

interface LayoutProps {
  children: React.ReactNode
}

export default function Layout({ children }: LayoutProps) {
  useTrackPageReady()
  useBackButton()
  useCSSHeightVariables()

  return (
    <>
      {/* <GreenTransition /> */}
      <Preloader />
      <GlobalStyle />
      <Modal>
        <ContactForm />
      </Modal>

      <Scroll>
        <Header />
        <Main id="main">{children}</Main>
        <Footer />
      </Scroll>
    </>
  )
}

const globalCss = css`
  color: ${colors.black900};

  * {
    /* need this so that fonts match figma */
    text-rendering: geometricprecision;
  }

  /* fixes for visbug */
  vis-bug {
    position: fixed;
    z-index: var(--layer-1);
  }

  visbug-metatip,
  visbug-handles,
  visbug-ally {
    position: absolute;
    z-index: var(--layer-top);
  }

  /** restore default focus states for elements that need them */
  *:focus-visible {
    outline: 2px solid #f008;
  }
`

const Main = styled.main`
  overflow-x: hidden;
  background: ${colors.gray600};

  ${fresponsive(css`
    .lines-children {
      position: relative;
      overflow: hidden;
      padding: 5px;
      margin: -5px;
    }
  `)}
`

const GlobalStyle = createGlobalStyle`${globalCss}`
