import * as Form from "@radix-ui/react-form"
import * as Select from "@radix-ui/react-select"
import { ReactComponent as ArrowSVG } from "images/global/downArrow.svg"
import ClientOnly from "library/ClientOnly"
import { fresponsive } from "library/fullyResponsive"
import { useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

const DROPDOWN_OPTIONS = [
  "Ashby",
  "Breezy",
  "Greenhouse",
  "Indeed",
  "Lever",
  "Linkedin",
  "ZipRecruiter",
  "Other",
]

export default function Dropdown() {
  const [value, setValue] = useState<string>()

  return (
    <>
      {/* mirror the state to the parent form */}
      <Control value={value} required aria-hidden />
      <Select.Root onValueChange={setValue}>
        {/* button to open/close selectbox */}
        <Trigger>
          <Select.Value placeholder="What hiring tool are you currently using?" />
          <LightArrow />
        </Trigger>

        <ClientOnly>
          {/* the content div */}
          <Select.Content
            position="popper"
            asChild
            sideOffset={0}
            style={{ zIndex: 11 }}
          >
            <Content>
              {DROPDOWN_OPTIONS.map(company => (
                <Item key={company} value={company}>
                  <Select.ItemText>{company}</Select.ItemText>
                  {/* <Select.ItemIndicator /> */}
                </Item>
              ))}
            </Content>
          </Select.Content>
        </ClientOnly>
      </Select.Root>
    </>
  )
}

const Control = styled(Form.Control)`
  visibility: hidden;
  position: absolute;
  pointer-events: none;

  &[data-invalid] ~ * {
    border-color: red !important;
    color: red !important;
  }
`

const Trigger = styled(Select.Trigger)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  transition-property: color;
  color: ${colors.black01};
  border: 1px solid ${colors.silver01};
  border-radius: 7px;
  ${textStyles.bodySmall};
  outline: none;
  white-space: nowrap;

  &[data-placeholder] {
    color: ${colors.gray800};

    &:hover {
      color: ${colors.black01};
    }
  }

  &:focus-visible {
  }

  ${fresponsive(css`
    padding: 12px 20px;
  `)}
`

const Arrow = styled(ArrowSVG)`
  ${fresponsive(css`
    width: 13px;
    transform-origin: top left;
  `)}
`

const LightArrow = styled(Arrow)`
  ${fresponsive(css``)}
`

const Content = styled.div`
  ${textStyles.bodySmall};
  ${fresponsive(css`
    position: absolute;
    z-index: 11;
    color: ${colors.grayWhite};
    top: 100%;
    border-radius: 12px;
    padding: 10px;
    width: 320px;
    background: ${colors.grayWhite};
    box-shadow: 0 10px 18px 0 rgb(0 0 0 / 10%);
    height: 300px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  `)}
`

const Item = styled(Select.Item)`
  color: ${colors.black01};
  background: ${colors.gray600};
  transition: color 0.2s ease-in-out;
  outline: none;
  cursor: pointer;
  display: grid;
  place-items: center;

  &:hover,
  &:focus-visible {
    background: ${colors.gray700};
  }

  ${fresponsive(css`
    width: 100%;
    padding: 10px;
    border-radius: 6px;
  `)}
`
