const colors = {
  black900: "#000",
  black800: "#0C0D24",
  black700: "#11141C",

  black01: "#17191B",

  silver01: "#D5DFE4",

  gray900: "#575F78",
  gray800: "#979EB4",
  gray700: "#C1C5D0",
  gray600: "#EEF0F6",
  grayWhite: "#FFF",

  blue900: "#247BED",
  blue800: "#2994FB",
  blue700: "#26A5F5",

  purple900: "#4F29BB",
  purple800: "#654CF1",
  purple700: "#8068F8",

  buttonHover: `linear-gradient(
      #5bc0f9 0%,
      #308aff 55.71%,
      #3288fa 55.72%,
      #5698ef 100%
    )`,
  buttonPrimary: `
   linear-gradient(
      #19a4f2 0%,
      #308aff 55.71%,
      #2c82f4 55.72%,
      #1e75e8 100%
    )`,
} as const

export default colors
