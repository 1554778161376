import { createContext, useMemo, useState } from "react"

/**
 * Allows for opening or closing of modal from anywhere in the app
 */
export const ModalContext = createContext({
  openModal: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setOpenModal: (__val: boolean) => {},
})

interface Props {
  children: React.ReactNode
}

export function ModalProvider({ children }: Props) {
  const [openModal, setOpenModal] = useState<boolean>(false)

  const modalValues = useMemo(() => {
    return { openModal, setOpenModal }
  }, [openModal])

  return (
    <ModalContext.Provider value={modalValues}>
      {children}
    </ModalContext.Provider>
  )
}
