import gsap from "gsap"
import type { ReactNode } from "react"
import { useContext, useEffect, useRef } from "react"
import { styled } from "styled-components"

import { ModalContext } from "./Providers/OpenModal"

export default function Modal({ children }: { children: ReactNode }) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const innerRef = useRef<HTMLDivElement>(null)
  const { openModal, setOpenModal } = useContext(ModalContext)

  const openAnim = () => {
    const tl = gsap.timeline()

    tl.set(
      wrapperRef.current,
      {
        display: "grid",
      },
      0,
    )

    tl.to(
      wrapperRef.current,
      {
        opacity: 1,
      },
      0.1,
    )

    tl.fromTo(
      innerRef.current,
      {
        yPercent: 10,
      },
      {
        yPercent: 0,
      },
      0.1,
    )
  }

  const closeAnim = () => {
    const tl = gsap.timeline()

    tl.to(
      innerRef.current,
      {
        yPercent: 10,
      },
      0,
    )

    tl.to(
      wrapperRef.current,
      {
        opacity: 0,
      },
      0,
    )

    tl.set(
      wrapperRef.current,
      {
        display: "none",
      },
      1,
    )
  }

  useEffect(() => {
    if (openModal) {
      openAnim()
    } else {
      closeAnim()
    }
  }, [openModal])

  const wrapperClick = () => {
    setOpenModal(false)
  }

  return (
    <Wrapper role="presentation" ref={wrapperRef} onClick={wrapperClick}>
      <Inner
        role="presentation"
        ref={innerRef}
        onClick={e => e.stopPropagation()}
      >
        {children}
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  color: black;
  background: rgb(7 13 35 / 50%);
  opacity: 0;
  display: none;
  place-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
`

const Inner = styled.div`
  position: relative;
`
