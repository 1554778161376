const socials = {
  twitter: "https://twitter.com/legworkapp",
  instagram: "https://www.instagram.com/legwork_app/",
  linkedin: "https://www.linkedin.com/company/legworkapp",
} as const

const routes = {
  about: "/",
  blog: "/blog",
  contact: "mailto:Hello@LegWorkapp.ai",
  terms: "/terms",
  privacy: "/privacy",
} as const

const links = {
  ...socials,
  ...routes,
  support: "mailto:support@legworkapp.ai",
} as const

export default links
