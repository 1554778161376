import gsap from "gsap"
import { ScrollSmoother } from "gsap/ScrollSmoother"
import { ReactComponent as ChipSVG } from "images/global/chip.svg"
import ConstantMarquee from "library/ConstantMarquee"
import { fresponsive } from "library/fullyResponsive"
import loader from "library/Loader"
import {
  registerLoaderCallback,
  unregisterLoaderCallback,
} from "library/Loader/LoaderUtils"
import useAnimation from "library/useAnimation"
import { useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import textStyles from "styles/text"

export default function Preloader() {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const innerTrackRef = useRef<HTMLDivElement>(null)

  useAnimation(
    () => {
      ScrollSmoother.get()?.scrollTop(0)

      const whiteLines = () => {
        const tl = gsap.timeline({
          repeat: -1,
        })

        tl.set(
          ".white-light",
          {
            fill: "#898C9C",
            stagger: 0.05,
          },
          0,
        )

        tl.set(
          ".white-light",
          {
            fill: "#262938",
            stagger: 0.05,
          },
          0.05,
        )

        return tl
      }

      const topWhiteLines = () => {
        const tl = gsap.timeline({
          repeat: -1,
        })

        tl.set(
          ".white-light-top",
          {
            fill: "#898C9C",
            stagger: { from: "end", each: 0.05 },
          },
          0,
        )

        tl.set(
          ".white-light-top",
          {
            fill: "#262938",
            stagger: { from: "end", each: 0.05 },
          },
          0.05,
        )

        return tl
      }

      const bulbs = (selector: string) => {
        const tl = gsap.timeline({
          repeat: -1,
          repeatDelay: 0.5,
        })

        tl.set(selector, {
          opacity: 1,
          stagger: {
            from: "random",
            each: 0.25,
            onComplete(this: { targets: () => SVGRectElement[] }) {
              /* eslint-disable-next-line react/no-this-in-sfc, @typescript-eslint/no-unsafe-call */
              const targets = this.targets()
              const target = targets[0]
              if (!target) return
              gsap.set(target, {
                delay: 0.35,
                opacity: 0,
              })
            },
          },
        })

        return tl
      }

      const mainTl = gsap.timeline({
        onStart: () => {
          ScrollSmoother.get()?.scrollTop(0)
        },
      })

      mainTl.add(whiteLines(), 0)
      mainTl.add(topWhiteLines(), 0)
      mainTl.add(bulbs(".blue-bulb"), 0)
      mainTl.add(bulbs(".purple-bulb"), 0)
      mainTl.add(bulbs(".green-bulb"), 0)
      mainTl.add(bulbs(".yellow-bulb"), 0)
    },
    [],
    { scope: wrapperRef.current },
  )

  useEffect(() => {
    const blah = (progress: number) => {
      gsap.to(innerTrackRef.current, {
        scaleX: progress / 100,
        duration: 0.1,
        ease: "power1.out",
      })
    }
    loader.addEventListener("progressUpdated", blah)

    const fadeOut = () => {
      gsap.to(wrapperRef.current, {
        borderRadius: 30,
        scale: 0.9,
        duration: 1,
        ease: "power2.out",
      })
      gsap.to(wrapperRef.current, {
        duration: 1,
        y: "100vh",
        ease: "power2.in",
        onComplete: () => {
          gsap.set(wrapperRef.current, {
            display: "none",
          })
        },
      })
    }
    registerLoaderCallback({
      callback: fadeOut,
      duration: 1,
    })

    return () => {
      unregisterLoaderCallback(fadeOut)
      loader.removeEventListener("progressUpdated", blah)
    }
  }, [])

  return (
    <Wrapper ref={wrapperRef}>
      <Blur>
        <Chip />
        <ChipBevel />
        <Pill>
          <StyledConstantMarquee timing={1} className="loader-marquee-1">
            <Text>Get Ready</Text>
          </StyledConstantMarquee>

          <Track>
            <InnerTrack ref={innerTrackRef} />
          </Track>
          <StyledConstantMarquee timing={1} className="loader-marquee-2">
            <Text>Get Ready </Text>
          </StyledConstantMarquee>
        </Pill>
      </Blur>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #060712;
  z-index: 110;
  pointer-events: none;
  display: grid;
  place-items: center;
  ${textStyles.h1}
`

const Blur = styled.div`
  position: absolute;
  z-index: 1;
  background: radial-gradient(#1c1c26 0%, transparent 70%);
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  ${fresponsive(css`
    width: 710px;
    height: 710px;
  `)}
`

const Chip = styled(ChipSVG)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  .bulb {
    opacity: 0;
  }

  ${fresponsive(css`
    width: 328px;
    height: 360px;
    bottom: 150px;
  `)}
`

const ChipBevel = styled.div`
  position: absolute;
  z-index: 0;
  left: 50%;
  transform: translateX(-50%);

  ${fresponsive(css`
    width: 184px;
    height: 184px;
    bottom: 292px;
    border-radius: 8px;
    border: 1px solid #070813;
    background: #141726;
    box-shadow:
      0.5px 0.5px 0 0 #070813,
      -0.5px -0.5px 0 0 #070813,
      0 1px 2px 1px rgb(255 255 255 / 10%) inset;
  `)}
`

const Pill = styled.div`
  position: absolute;
  border: 1px solid #293046;
  background: #141829;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  ${fresponsive(css`
    border-radius: 48px;
    width: 328px;
    height: 40px;
    bottom: 162px;
    padding: 11px 0;
    gap: 9px;
  `)}
`

const StyledConstantMarquee = styled(ConstantMarquee)`
  position: relative !important;
  overflow: hidden;
  left: 0;
  translate: 0;

  ${fresponsive(css`
    width: 56px;
  `)}
`

const Text = styled.div`
  ${textStyles.kickerOne};
  color: #919db7;

  ${fresponsive(css`
    padding-right: 4px;
  `)}
`

const Track = styled.div`
  ${fresponsive(css`
    width: 140px;
    height: 8px;
    border: 1px solid #293046;
    border-radius: 30px;
    background: #05091a;
    padding: 2px;
  `)}
`

const InnerTrack = styled.div`
  border-radius: 4px;
  background: rgb(165 229 206 / 80%);
  transform-origin: left;

  ${fresponsive(css`
    height: 100%;
    transform: scaleX(0);
    box-shadow: 0 0 7px 7px rgb(135 186 170 / 15%);
  `)}
`
