import { fresponsive } from "library/fullyResponsive"
import { css } from "styled-components"

const textStyles = {
  h1: fresponsive(css`
    font-family: Gilroy, sans-serif;
    font-size: 105px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 126px */
    letter-spacing: -5.25px;
  `),

  h2: fresponsive(css`
    font-family: Gilroy, sans-serif;
    font-size: 80px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 80px */
    letter-spacing: -3.2px;
  `),
  h3: fresponsive(css`
    font-family: Gilroy, sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: 108%; /* 36.72px */
    letter-spacing: -1.36px;
  `),
  h4: fresponsive(css`
    font-family: Gilroy, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
    letter-spacing: -0.96px;
  `),
  bodyRegular: fresponsive(css`
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.32px;
  `),
  bodyBold: fresponsive(css`
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
    letter-spacing: -0.32px;
  `),
  bodySmall: fresponsive(css`
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%; /* 18.9px */
    letter-spacing: -0.28px;
  `),
  kickerOne: fresponsive(css`
    font-family: "PP Fraktion Mono", monospace;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 16.5px */
    letter-spacing: 0.66px;
    text-transform: uppercase;
  `),
  bodyButton: fresponsive(css`
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%; /* 18.9px */
    letter-spacing: -0.28px;
  `),
}

export const strokeText = css`
  /* styled doesn't prefix this property yet */
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-size: 100%;
  background-clip: text;
  -webkit-text-stroke-width: 0.07vw;
`

export default textStyles
