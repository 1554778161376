import Button from "components/Button"
import { ModalContext } from "components/Providers/OpenModal"
import { ReactComponent as LogoSVG } from "images/global/logo-no-underline.svg"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import UniversalLink from "library/Loader/UniversalLink"
import { useContext } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

export default function Header() {
  const { setOpenModal } = useContext(ModalContext)

  return (
    <Wrapper id="header">
      <UniversalLink to="/" ariaLabel="Legwork Home">
        <Logo />
      </UniversalLink>
      <Links>
        <SignIn to="https://app.legworkapp.ai/login">Sign In</SignIn>
        <Button
          className="header-button"
          type="button"
          onClick={() => setOpenModal(true)}
        >
          Get Early Access
        </Button>
      </Links>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  position: absolute;
  z-index: 9;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 50%;
  transform: translateX(-50%);

  ${fresponsive(css`
    top: 50px;
    width: 1400px;
    height: 47px;
    border-radius: 50px;
    padding: 0 30px;
  `)}

  ${ftablet(css`
    top: 50px;
    width: 924px;
    height: 47px;
    border-radius: 50px;
    padding: 0 30px;
  `)}

  ${fmobile(css`
    top: 30px;
    width: 355px;
    height: 28px;
    padding: 0;
  `)}
`

const Logo = styled(LogoSVG)`
  height: auto;

  ${fresponsive(css`
    width: 118px;
  `)}
`

const Links = styled.div`
  display: flex;
  align-items: center;

  ${fresponsive(css`
    justify-content: flex-end;
    gap: 50px;
    width: 100%;
  `)}

  ${fmobile(css`
    display: none;
  `)}
`

const SignIn = styled(UniversalLink)`
  ${textStyles.bodyButton};
  color: ${colors.blue900};
`
