import StyledManager from "library/StyledManager"

import { ModalProvider } from "./OpenModal"
import { ScreenProvider } from "./Screen"

interface ProvidersProps {
  children: React.ReactNode
}

export default function Providers({ children }: ProvidersProps) {
  return (
    <StyledManager>
      <ScreenProvider>
        <ModalProvider>{children}</ModalProvider>
      </ScreenProvider>
    </StyledManager>
  )
}
