import { styled } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

const Input = styled.input`
  ${textStyles.bodySmall};
  padding: 12px 20px;
  color: ${colors.black01};
  caret-color: black;
  border: 1px solid ${colors.silver01};
  transition: 0.2s ease-in-out;
  transition-property: border-bottom, color;
  border-radius: 7px;
  width: 100%;
  display: block;

  &::placeholder {
    color: ${colors.gray800};
    transition: inherit;
  }

  &:hover {
    &::placeholder {
      color: ${colors.black01};
    }
  }

  &:focus-visible {
    outline: none;
  }

  &[data-invalid] {
    color: red;
    border: 1px solid red;

    &::placeholder {
      color: ${colors.purple800};
    }
  }
`

export default Input
