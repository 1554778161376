import * as Form from "@radix-ui/react-form"
import * as VisuallyHidden from "@radix-ui/react-visually-hidden"
import Primary from "components/Button"
import { ModalContext } from "components/Providers/OpenModal"
import { gsap } from "gsap"
import { ReactComponent as CloseSVG } from "images/global/close.svg"
import { ReactComponent as SuccessSVG } from "images/global/formSuccess.svg"
import { ReactComponent as LogoSVG } from "images/global/logo-no-underline.svg"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import UniversalLink from "library/Loader/UniversalLink"
import useAnimation from "library/useAnimation"
import { useContext, useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

import DropDown from "./Dropdown"
import Input from "./Input"

export default function ContactForm() {
  const { setOpenModal } = useContext(ModalContext)
  const [success, setSuccess] = useState(false)
  const [bottomContent, setBottomContent] = useState<HTMLDivElement | null>(
    null,
  )
  const [successContent, setSuccessContent] = useState<HTMLDivElement | null>(
    null,
  )

  useAnimation(() => {
    if (!bottomContent || !successContent) return
    if (success) {
      gsap.to(bottomContent, { autoAlpha: 0, duration: 0.4 })
      gsap.to(successContent, { autoAlpha: 1, duration: 0.4 })
    } else {
      gsap.to(bottomContent, { autoAlpha: 1, duration: 0.4 })
      gsap.to(successContent, { autoAlpha: 0, duration: 0.4 })
    }
  }, [success, bottomContent, successContent])

  return (
    <FormRoot
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      name="contact-marketing"
      onSubmit={e => {
        e.preventDefault()

        const data = new FormData(e.currentTarget) as unknown as Record<
          string,
          string
        >
        const submission = new URLSearchParams(data).toString()

        fetch("/", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: submission,
        })
          .then(res => {
            if (res.ok) {
              setSuccess(true)
            }

            return null
          })
          .catch((error: Error) => {
            console.error(error)
          })
      }}
    >
      <Close
        onClick={() => setOpenModal(false)}
        type="button"
        aria-label="close the form modal"
      >
        <CloseSVG />
      </Close>
      <Logo />
      <SubTitle>The world’s only AI Driven Talent Tracking System.</SubTitle>
      <ContentWrapper>
        <BottomContent ref={setBottomContent}>
          <Fields>
            <input type="hidden" name="form-name" value="contact-marketing" />
            <Form.Field name="name">
              <VisuallyHidden.Root>
                <Form.Label>First Name</Form.Label>
              </VisuallyHidden.Root>
              <Form.Control asChild>
                <Input placeholder="First Name" required />
              </Form.Control>
              <FormMessage match="valueMissing">
                What&apos;s Your Name...
              </FormMessage>
            </Form.Field>

            <Form.Field name="email">
              <VisuallyHidden.Root>
                <Form.Label>Email</Form.Label>
              </VisuallyHidden.Root>
              <Form.Control asChild>
                <Input placeholder="Email" required type="email" />
              </Form.Control>
              <FormMessage match="valueMissing">
                What&apos;s Your Work Email Address...
              </FormMessage>
              <FormMessage match="typeMismatch">
                Please enter a valid email
              </FormMessage>
              {/* forbid dotless domains */}
              <FormMessage match={v => !/@.*\./.test(v)}>
                Please enter a valid email
              </FormMessage>
            </Form.Field>

            <Form.Field name="hiringTool">
              <VisuallyHidden.Root>
                <Form.Label>Hiring Tool</Form.Label>
              </VisuallyHidden.Root>
              <DropDown />
              <FormMessage match="valueMissing">
                What hiring tool are you currently using?
              </FormMessage>
            </Form.Field>

            <Form.Submit asChild>
              <FullWidthPrimary type="submit">
                Join the Waitlist
              </FullWidthPrimary>
            </Form.Submit>
          </Fields>

          <Message>
            The wait won’t be long. We are actively adding teams daily. We
            promise, it’s worth the very short wait.
          </Message>
        </BottomContent>
        <FormSuccess ref={setSuccessContent}>
          <StyledSuccessSVG />
          <SuccessTitle>You&apos;re In!</SuccessTitle>
          <Message>
            We’ve marked you down and will get you access as soon as we can!
          </Message>
        </FormSuccess>
      </ContentWrapper>
    </FormRoot>
  )
}

const FormRoot = styled(Form.Root)`
  position: relative;
  overflow: hidden;
  background: ${colors.grayWhite};
  ${fresponsive(css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 510px;
    padding: 78px 83px 47px 82px;
    height: auto;
    border-radius: 16px;
  `)}

  ${ftablet(css`
    gap: 24px;
  `)}
  ${fmobile(css`
    width: 355px;
    padding: 20px;
    gap: 16px;
  `)}
`

const Close = styled(UniversalLink)`
  ${fresponsive(css`
    position: absolute;
    cursor: pointer;
    right: 30px;
    top: 30px;
    width: 26px;
    height: 26px;
    border-radius: 5px;
    border: 1px solid ${colors.silver01};
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 5%);
    display: grid;
    place-items: center;

    svg {
      width: 13px;
      height: 13px;
    }
  `)}
`

const Logo = styled(LogoSVG)`
  height: auto;

  ${fresponsive(css`
    width: 166px;
    position: relative;
    display: flex;
  `)}
`

const SubTitle = styled.div`
  ${textStyles.bodyRegular};
  color: "#3C454F";
  ${fresponsive(css`
    margin-top: 10px;
  `)}
  ${fmobile(css``)}
`

const ContentWrapper = styled.div`
  position: relative;
`

const BottomContent = styled.div`
  ${fresponsive(css``)}
  ${ftablet(css``)}
${fmobile(css``)}
`

const Fields = styled.div`
  display: flex;
  flex-direction: column;

  ${fresponsive(css`
    gap: 16px;
    margin: 44px 0 57px;
    width: 320px;
  `)}
  ${fmobile(css`
    width: 100%;
  `)}
`

const FormMessage = styled(Form.Message)`
  ${textStyles.bodySmall};
  color: red;
  display: block;

  ${fresponsive(css`
    height: 12px;
    padding: 4px;
  `)}
`

const FullWidthPrimary = styled(Primary)`
  width: 100%;
  place-self: end end;

  ${ftablet(css`
    margin-top: calc(60px - 24px);
  `)}
  ${fmobile(css`
    margin-top: 4px;
  `)}
`

const Message = styled.p`
  color: "#3C454F";
  ${textStyles.bodySmall};
  ${fresponsive(css`
    width: 300px;
    text-align: center;
  `)}
  ${ftablet(css``)}
${fmobile(css``)}
`

const FormSuccess = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledSuccessSVG = styled(SuccessSVG)`
  position: relative;
  ${fresponsive(css`
    margin-top: 41px;
    width: 215px;
    height: 215px;
  `)}
  ${ftablet(css``)}
${fmobile(css``)}
`

const SuccessTitle = styled.h4`
  ${textStyles.h4};
  color: ${colors.black01};
  ${fresponsive(css`
    margin: 14px 0;
  `)}
`
